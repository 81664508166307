import http from '../clients/Axios'

export default class PajakSeriService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/pajak/seri/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}&timestamp=${new Date().getTime()}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    return await http.get(`/pajak/seri${param}`)
  }

  async add(form) {
    return await http.post('/pajak/seri', form)
  }

  async update(id, form) {
    return await http.post(`/pajak/seri/${id}`, form)
  }

  async delete(id) {
    return await http.delete(`/pajak/seri/${id}`)
  }

  async count(param) {
    return await http.get(`/pajak/seri/count?filter[status]=${param}`)
  }

  async print(id) {
    return http({
      url: `/pajak/seri/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async export(sortField, sortOrder, filters) {
    const unixTime = Math.floor(Date.now() / 1000)
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }
    return http({
      url: `/pajak/seri/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportCsv(start_date, end_date) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/pajak/seri/sales/xls?filter[order_between]=${start_date},${end_date}&filter[status]=assigned&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
